.navbar-notifications-box {
    width: 48rem;
    max-height: 64rem;
    overflow: hidden;
    overflow-y: scroll;
    text-align: left;
    padding: 1.2rem;

    .global-actions {
        padding: 0rem 1.2rem;
        display: flex;
        justify-content: space-between;
    }
    > hr { margin: 0.6rem 0 0; }

    .empty {
        min-width: 24rem;
        margin: 1.2rem 1.2rem;
        padding: 2.4rem 1.2rem;
        border: 1px dashed @brand-primary;
        text-align: center;
        font-size: 1.8rem;
        line-height: 1.6;
    }
    > a {
        display: block;
        width: 48rem;
        padding: 0.6rem 1.2rem;
    }

    &.navbar-billings {
        padding: 0.6rem 1.2rem;
    }

    .billings-header {
        font-size: 1.8rem;
        line-height: 1.4;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-top: -0.6rem;
        padding: 0 1.2rem;
    }

    .nav-note-row {
        display: flex;
        align-items: center;
        margin: 0 -1.2rem;
        padding: 0.6rem 1.2rem;
        text-align: left;
        &:hover {
            background-color: darken(#fff, 10%);
        }

        > * {
            padding: 0 1.2rem;
            flex: 0 0 auto;
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }

        .avatar {
            width: 4.8rem;
            border-right: 1px solid darken(#fff, 20%);
        }

        .actions {
            font-size: 1.8rem;
            line-height: 1.8rem;
            .far {
                cursor: pointer;
            }
        }

        .details {
            width: 30%;
            flex: 1 1 auto;
            text-align: left;
            .name {
                font-size: 1.8rem;
                line-height: 1.4;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                padding-bottom: 0.3rem;
            }
            .note-message {
                font-size: 1.4rem;
                line-height: 1.4;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                padding-bottom: 0.3rem;
            }

            .description {
                font-family: @font-family-sans-serif;
                font-size: 1rem;
                line-height: 1.6;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                color: black;
                a,
                a:visited {
                    color: @gray;
                }
            }

        }


    }

    .note-parent-row {
        margin-top: 2.4rem;
    }



}


.list-contents {
    .notification-pref-line {
        display: flex;
        align-items: center;
        > * {
            margin-left: 1.2rem;
        }
        .desc {
            font-size: 1.8rem;
            flex: 1 1 auto;
            margin-left: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden
        }
    }
}