.details-header {
    padding-top: 2.4rem;
    //padding-bottom: 2.4rem;
    margin-bottom: 2.4rem;
    // background-image: url(/img/headers/basicbg.jpg);

    background: radial-gradient(ellipse farthest-corner at center center, lighten(@brand-primary, 10%), spin(@brand-primary, 10) 50%, spin(@brand-primary, 30));
    background-position: top center;
    background-size: 100%;
    background-repeat: no-repeat;

    .headline {
        text-shadow: 2px -2px 0 rgb(11, 168, 149);
        font-size: 6.4rem;
        font-family: @headings-font-family;
        color: #fff;
        margin-left: 32.4rem;
        margin-bottom: 2.4rem;

        &.long { font-size: 4.8rem; padding-top: 1.6rem;}
        &.xlong { font-size: 3.6rem; padding-top: 2.8rem;}
    }


    .subheader {
        background-color: fadeout(@brand-primary, 25%);
        // background: linear-gradient( 0deg, @brand-primary, fadeout(@brand-primary, 25%));
        position: relative;
        margin: 0 -3.2rem 2.4rem;
        padding: 2.4rem 3.2rem;
        min-height: 22.4rem;

        .profile-photo {
            width: 30rem;
            height: 30rem;
            //margin-top: -5rem;
            background-color: #fff;
            box-shadow: 2px 2px 10px rgba(0,0,0,0.25);
            padding: 2.4rem;
            position: absolute;
            top: -10rem;

        }

        .actions {
            position: absolute;
            right: 3.2rem;
            font-size: 2.4rem;
            text-align: right;
            a {
                color: darken(#fff, 15%);
            }

        }

        .description {
            font-family: @headings-font-family;
            color: darken(#fff, 15%);
            margin-left: 32.4rem;
            font-size: 2.4rem;
            a {
                color: darken(#fff, 15%);
                text-decoration: none;
                &:hover,
                &:focus {
                    color: darken(#fff, 15%);
                    text-decoration: underline;
                }
            }


        }

        .info {
            color:#fff;
            display: flex;
            flex-wrap: wrap;
            font-size: 1.4rem;
            margin-top: 2.4rem;
            margin-left:32.4rem;

            > * {
                margin-right: 2.4rem;
                width: 20rem;
                flex-grow: 1;
            }

            a {
                color: #fff;
                text-decoration: none;
                &:hover,
                &:focus {
                    color: #fff;
                    text-decoration: underline;
                }
            }
        }


    }

    .details-name {
        flex: 1 1 auto;
        margin: 1em 2em;
        h1 {
            line-height: 1;
            margin-top: 0;
        }
        h4 {
            color: @gray-light;
        }
        .info {
            display: flex;
            ul {
                flex: 1 1 auto;
            }
        }
    }
    .details-action {
        width: 3em;
        font-size: 3rem;
        text-align: right;
        a {
            color: @gray-light;
        }
    }
}

@media screen and (max-width: 768px){
    .details-header {
        .subheader{
            .description {
                margin-left: 0;
            }
            .info {
                margin-left: 0;
            }
        }
        .headline{
            margin-left: 0;
        }
        .profile-photo {
            display:none;
        }
    }
}