.goodsline {
    margin: 0 -1.2rem;
    padding: 0.24rem 1.2rem;
    
    .row > div {
        margin-top: 1.2rem;
    }
    .goods-actions {
            text-align: right;
            i {
                font-size: 2.4rem;
            }
        }
    
    &.to-delete {
        background-color: rgba(255, 0, 0, 0.25);
        input {
            background-color: rgb(250, 250, 250);
        }
    }
}


.dashboard-widget {
    .shipment-line {
        // display: flex;
        // padding: 0.6rem;
        // font-size: 1.4rem;
        &:nth-child(even) {
            background-color: @table-bg-accent;
        }

        &.heading {
            flex: 1 1 auto;
            font-weight: bold;
            text-overflow: ellipsis;
            flex-wrap: nowrap;
        }
        .number {
            flex: 0 0 auto;
            width: 15%;
            overflow: hidden;
            text-overflow: ellipsis;
            flex-wrap: wrap;
        }
        .name {
            flex: 0 0 auto;
            width: 45%;
            overflow: hidden;
            text-overflow: ellipsis;
            flex-wrap: wrap;
        }
        .status {
            flex: 0 0 auto;
            width: 15%;
            overflow: hidden;
            text-overflow: ellipsis;
            flex-wrap: wrap;

        }
        .eta {
            flex: 0 0 auto;
            width: 12%;
            overflow: hidden;
            text-overflow: ellipsis;
            flex-wrap: wrap;

        }
        .etd { 
            flex: 0 0 auto;
            width: 18%;
            overflow: hidden;
            text-overflow: ellipsis;
            flex-wrap: wrap;

        }

    }

    
}
slate-widget-shipments-line {
    display: block;
    position: relative;
    &:nth-child(even) {
        background-color: @table-bg-accent;
    }
}