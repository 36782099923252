slate-orderplan {
    .button-float {
        position: absolute;
        right: 3.6rem;
        top: 3.6rem;
        z-index: 600;
    }

    .op-container {
        width: 100%;
        overflow-x: scroll;
        position: relative;
        padding-top: 3.6rem;

        .op-display {
            width: 100%;
            position: relative;
        }
        .op-lines {
            position: absolute;
            top: 0;
            left: 0;
            min-width: 100%;
            min-height: 100%;
            right: 0;
            bottom: 0;
            z-index: 200;

        }


    }

    .op-row {
        white-space: nowrap;
        display: flex;
        align-items: flex-start;

        .op-leaf {
            white-space: nowrap;
            flex: 0 0 auto;
            position: relative;
            display: inline-block;
            text-align: center;


            .op-leaf-children {
                white-space: nowrap;
                display: flex;
                align-items: flex-start;
            }
        }

        .op-step {
            display: inline-block;
            position: relative;
            width: 24rem;

            box-shadow: 5px 5px 5px rgba(0,0,0,0.25);

            border-radius: 6px;
            border: 1px solid #999;
            background: rgba(235,235,235,0.9);
            padding: 6px;
            margin-right: 3.6rem;
            margin-bottom: 7.2rem;
            z-index: 800;

            text-align: left;

            .op-step-connector {
                position: absolute;
                width: 1.6rem;
                height: 1.6rem;
                border-radius: 0.8rem;
                background-color: #0000aa;
                border: 0.24rem solid #fff;
                margin-left: -0.8rem;
                left: 50%;
                padding: 0.24rem;
                overflow: hidden;

                .op-connector-dot {
                    margin-top: -0.24rem;
                    margin-left: -0.24rem;
                    width: 1.6rem;
                    height: 1.6rem;
                }

                &.top-connector {
                    top: -0.8rem;
                }
                &.bottom-connector {
                    bottom: -0.8rem;
                }
            }

            .op-step-head {
                margin: -6px -6px 6px;
                padding: 12px 6px 6px;
                background-color: rgba(200,200,200, 0.5);
                overflow: hidden;
                border-radius: 6px 6px 0 0;

                .op-step-actions {
                    > * { margin-left: 0.6rem;}
                }

                &.ready { background-color: rgb(200,200,255); }
                &.completed {
                    background-color: rgb(200,255,200);
                    i.fal {
                        color: rgb(0,155,0);
                    }
                }

            }

            .op-step-name {
                display: inline-block;
                font-size: 1.6rem;
                max-width: 18rem;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .op-step-details {
                height: 6.4rem;
                label {
                    font-size: 1rem;
                    margin-bottom: 2px;;
                }
                .step-detail-line {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden
                }
            }

            &.op-type-start {
                .op-step-head {
                    background-color: rgb(200,255,200);
                    i.fal {
                        color: rgb(0,155,0);
                    }
                }
            }
        }
    }


}

.op-step-edit-dialog {
    .op-add-parent-selector {
        display: flex;
        align-items: center;

        .op-add-parent-actions {
            flex: 0 0 auto;
            font-size: 1.6rem;
            margin-left: 1.6rem;
        }
        select {
            flex: 1 1 auto;
        }
    }
}