.dashboard {
    .actions {
        text-align: right;
        font-size: 1.6rem;
        padding-bottom: 2.4rem;
        > a, a.action {
            text-shadow: 5px 5px 5px @brand-primary;
            color: #fff;
            &:hover {
                color: #fff;
            }
            &:visited {
                color: #fff;
            }
        }
    }
    min-height: calc(100vh - 26rem);
    margin-bottom: 0;
    padding-bottom: 2.4rem;
    &.details-header {
        background-image: @default-wallpaper;
        background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 50%), @default-wallpaper;
        background-repeat: no-repeat, repeat-x;
        background-attachment: fixed;
        background-size: cover;
        background-position: center center;
    }
}

.dashboard.galaxy.details-header { background-image: url("/img/backgrounds/galaxy-sm.jpg"); }
.dashboard.beach.details-header { background-image: url("/img/backgrounds/beach-sm.jpg"); }
.dashboard.rain.details-header { background-image: url("/img/backgrounds/rain-sm.jpg"); }
.dashboard.abstract.details-header { background-image: url("/img/backgrounds/abstract.png"); }
.dashboard.moon.details-header { background-image: url("/img/backgrounds/moon-sm.jpg"); }
.dashboard.spooky.details-header { background-image: url("/img/backgrounds/spooky-sm.png"); }
.dashboard.hypno.details-header {background-image: none;
    background: repeating-radial-gradient(
        circle,
        #5bc0de,
        #5bc0de 10px,
        #005587 10px,
        #005587 20px
        );
        background-attachment: fixed;
    }
.dashboard.argyle.details-header {
    background-image: none;
    background:
    repeating-linear-gradient(120deg, rgba(255,255,255,.1), rgba(255,255,255,.1) 1px, transparent 1px, transparent 60px),
    repeating-linear-gradient(60deg, rgba(255,255,255,.1), rgba(255,255,255,.1) 1px, transparent 1px, transparent 60px),
    linear-gradient(60deg, rgba(0,0,0,.1) 25%, transparent 25%, transparent 75%, rgba(0,0,0,.1) 75%, rgba(0,0,0,.1)),
    linear-gradient(120deg, rgba(0,0,0,.1) 25%, transparent 25%, transparent 75%, rgba(0,0,0,.1) 75%, rgba(0,0,0,.1)),
    #6d695c,;
    background-size: 70px 120px;
}
.dashboard.tartan.details-header {
    background-image: none;
    background: repeating-linear-gradient(transparent, transparent 50px, rgba(0,0,0,.4) 50px, rgba(0,0,0,.4) 53px, transparent 53px, transparent 63px, rgba(0,0,0,.4) 63px, rgba(0,0,0,.4) 66px, transparent 66px, transparent 116px, rgba(0,0,0,.5) 116px, rgba(0,0,0,.5) 166px, rgba(255,255,255,.2) 166px, rgba(255,255,255,.2) 169px, rgba(0,0,0,.5) 169px, rgba(0,0,0,.5) 179px, rgba(255,255,255,.2) 179px, rgba(255,255,255,.2) 182px, rgba(0,0,0,.5) 182px, rgba(0,0,0,.5) 232px, transparent 232px),
    repeating-linear-gradient(270deg, transparent, transparent 50px, rgba(0,0,0,.4) 50px, rgba(0,0,0,.4) 53px, transparent 53px, transparent 63px, rgba(0,0,0,.4) 63px, rgba(0,0,0,.4) 66px, transparent 66px, transparent 116px, rgba(0,0,0,.5) 116px, rgba(0,0,0,.5) 166px, rgba(255,255,255,.2) 166px, rgba(255,255,255,.2) 169px, rgba(0,0,0,.5) 169px, rgba(0,0,0,.5) 179px, rgba(255,255,255,.2) 179px, rgba(255,255,255,.2) 182px, rgba(0,0,0,.5) 182px, rgba(0,0,0,.5) 232px, transparent 232px),
    repeating-linear-gradient(125deg, transparent, transparent 2px, rgba(0,0,0,.2) 2px, rgba(0,0,0,.2) 3px, transparent 3px, transparent 5px, rgba(0,0,0,.2) 5px)
    hsl(2, 57%, 40%);
}
.dashboard.wales.details-header {
    background-image: none;
    background: fixed center/cover url("/img/backgrounds/gb-wls.svg");
}
.dashboard.england.details-header {
    background-image: none;
    background: fixed center/cover url("/img/backgrounds/flag_of_britan.svg");
}
.dashboard.grizzly.details-header {
    background-image: none;
    background: fixed center/cover url("/img/backgrounds/grizzly.jpg");
}

@media screen and (min-width: 1281px) {
    .dashboard.galaxy.details-header { background-image: url("/img/backgrounds/galaxy-md.jpg"); }
    .dashboard.beach.details-header { background-image: url("/img/backgrounds/beach-md.jpg"); }
    .dashboard.rain.details-header { background-image: url("/img/backgrounds/rain-md.jpg"); }
    .dashboard.moon.details-header { background-image: url("/img/backgrounds/moon-md.jpg"); }
    .dashboard.spooky.details-header { background-image: url("/img/backgrounds/spooky-md.png"); }
}

@media screen and (min-width: 1921px) {
    .dashboard.galaxy.details-header { background-image: url("/img/backgrounds/galaxy-lg.jpg"); }
    .dashboard.beach.details-header { background-image: url("/img/backgrounds/beach-lg.jpg"); }
    .dashboard.rain.details-header { background-image: url("/img/backgrounds/rain-lg.jpg"); }
    .dashboard.moon.details-header { background-image: url("/img/backgrounds/moon-lg.jpg"); }
    .dashboard.spooky.details-header { background-image: url("/img/backgrounds/spooky-lg.jpg"); }
}


.dashboard-widget {
    background-color: fadeout(#fff, 15%);
    box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.3);
    overflow-y: scroll;
    overflow-x: auto;
    max-width: 100%;

    .widget-header {
        position: sticky;
        top: 0;
        background: @brand-primary;
        color: fadeout(#fff, 20%);
        padding: 0.6rem 1.2rem;
        font-size: 1.4rem;
        z-index: 400;
        .widget-actions {
            color: fadeout(#fff, 50%);
            a, i {

                color: fadeout(#fff, 50%);
                cursor: pointer;
                &:visited {
                    color: fadeout(#fff, 50%);
                }
                &:hover {
                    color: fadeout(#fff, 20%);
                }

            }
        }
    }
    .widget-body {
        padding: 1.2rem;
        overflow-wrap: break-word;
        max-width: 100%;
        .list-footer {
            width: 100%;
            display: flex;
            align-items: center;
            > * {
                margin-right: 2.4rem;
                flex: 0 0 auto;
                &:last-child {
                    margin-right: 0;
                }
                &.items-per-page-label {
                    margin-right: 0;
                }
            }
            .pagination-sm > li > a, .pagination-sm > li > span {
                font-size: 1.8rem;
            }
            .spacer {
                flex: 1 1 auto;
            }
        }

        .table-striped {

            > tbody > tr:nth-of-type(odd) {
              background-color: fadeout(@table-bg-accent, 75%);
            }
            > tbody > tr:hover {
                background-color: fadeout(@brand-primary, 75%);
            }
        }
    }
}

slate-widget-milestones {
    .incomplete_milestone {
        border-bottom: 1px solid @gray-lighter;
        padding-bottom: 0.6rem;
        margin-bottom: 1.2rem;
        &:last-child {
            margin-bottom: 0;
            border-bottom: 0;
        }
        > div {
            margin-bottom: 0.6rem;
        }
        .name {
            font-size: 1.8rem;
            line-height: 1.2;
        }
        .links {
            display: flex;
            a {
                width: 50%;
                flex: 1 0 auto;
                display: inline-block;
            }
        }
        .info {
            display: flex;
            span {
                width: 50%;
                flex: 1 0 auto;
                display: inline-block;
            }
        }
    }
}

slate-widget-requests {
    .requests {
        border-bottom: 1px solid @gray-lighter;
        padding-bottom: 0.6rem;
        margin-bottom: 1.2rem;
        &:last-child {
            margin-bottom: 0;
            border-bottom: 0;
        }
        > div {
            margin-bottom: 0.6rem;
        }
        .name {
            font-size: 1.8rem;
            line-height: 1.2;
        }
        .links {
            display: flex;
            a {
                width: 50%;
                flex: 1 0 auto;
                display: inline-block;
            }
        }
        .info {
            display: flex;
            > span {
                width: 50%;
                flex: 1 0 auto;
                display: inline-block;
            }
        }
    }
}

slate-widget-porequests, slate-widget-purchaseorders, slate-widget-salesorders, slate-widget-salesquotes,slate-widget-invrequests {
    .porequests, .invoicerequests {
        border-bottom: 1px solid @gray-lighter;
        padding-bottom: 0.6rem;
        margin-bottom: 1.2rem;
        &:last-child {
            margin-bottom: 0;
            border-bottom: 0;
        }
        > div {
            margin-bottom: 0.6rem;
        }
        .name {
            font-size: 1.8rem;
            line-height: 1.2;
        }
        .links {
            display: flex;
            width: 100%;
            > * {
                max-width: 15rem;
                flex: 1 1 25%;
                margin-right: 1.2rem;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .files {
                max-width: 100%;
                flex: 1 1 25%;
                text-align: right;
                margin-right: 0;
                white-space: normal;
                overflow: auto;
            }
        }
        .info {
            display: flex;
            span {
                width: 50%;
                flex: 1 0 auto;
                display: inline-block;
            }
        }
    }
}

slate-widget-po-receive {
    .poreceives {
        border-bottom: 1px solid @gray-light;
        padding-bottom: 0.6rem;
        margin-bottom: 1.2rem;
        &:last-child {
            margin-bottom: 0;
            border-bottom: 0;
        }
        .form-control { background-color: #fff; ;}
        > div {
            margin-bottom: 0.24rem;
        }
        .name {
            font-size: 1.6rem;
            line-height: 1.2;

            .po-num {
                display: inline-block;
                width: 6em;
            }
            .po-date { font-size: 1.2rem; margin-right: 0.6rem;}
        }
        .details {
            padding: 2.4rem 0 0 2.4rem;
            padding-left: 2.4rem;
            width: 100%;
            .nope {
                max-width: 15rem;
                flex: 1 1 25%;
                margin-right: 1.2rem;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .files {
                max-width: 100%;
                flex: 1 1 auto;
                text-align: right;
                margin-right: 0;
                white-space: normal;
                overflow: auto;
            }
        }
        .info {
            display: flex;
            span {
                width: 50%;
                flex: 1 0 auto;
                display: inline-block;
            }
        }
    }
}


slate-widget-proj-notifications {

    .notifications {
        width: 100%;
        margin: 0 0 0.48rem;
        padding: 0.48rem;
        display: flex;
        align-content: center;
        &:hover {
            background-color: fadeout(@brand-primary, 75%);
        }
        .notification-company {
            width: 20%;
            line-height: 1.8rem;
            flex: 0 0 auto;
            margin-right: 0.12rem;
            text-overflow: ellipsis;
        }
        .notification-name {
            width: 30%;
            line-height: 1.8rem;
            flex: 0 0 auto;
            margin-right: 0.12rem;
            text-overflow: ellipsis;
        }
        .notification-subject {
            flex: 1 1 auto;
            line-height: 1.8rem;
            margin-right: 0.12rem;
        }
        .notification-actions {
            flex: 0 0 auto;
            min-width: 3rem;
            font-size: 1.8rem;
            line-height: 1.8rem;
            text-align: right;
            font-weight: bold;
        }
    }
}

.dashboard-rfq-entry  {
    width: 100%;
    padding: 0.6rem;
    margin: -0.6rem;
    .name {
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 0.2rem;
    }
    .details {
        display: flex;
        > span {
            width: 25%;
            font-size: 1.4rem;
            flex: 1 1 auto;
        }
    }
    .status {
        margin-right: 0.6rem;
    }
    &:hover {
        background: rgba(0,85,135, 0.25)
    }
}