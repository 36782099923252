.auth-page {
    flex: 1 1 auto;
    margin-top: -2px;
    background-image: url(/img/headers/basicbg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: repeat-x;
    min-height: 50rem;
    height: 100%;
    position: relative;
    padding: 4.8rem 4.8rem 9.6rem;

    .auth-box {
        background-color: #fff;
        box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.25);

        display: flex;
        flex-direction: column;

        width: 38rem;
        min-height: 38rem;
        margin: 0 0 0 auto;
        padding: 2.4rem;

        >* {
            flex: 0 0 auto;
            display: block;
        }

        .flex-spacer {
            flex: 1 1 auto;
            min-height: 4.8rem;
        }

        form>* {
            margin-bottom: 2.4rem;
        }

        heading {
            font-family: @headings-font-family;
            font-weight: @headings-font-weight;
            line-height: @headings-line-height;
            color: @headings-color;
            font-size: @font-size-h3;
        }
    }

    .okta-auth-box {
        display: flex;
        flex-direction: column;

        min-width: 46rem;
        min-height: 90rem;
        margin: 0 0 0 auto;
        padding: 2.4rem;

        >* {
            flex: 0 0 auto;
        }
    }

    &.okta-login-page {
        display: flex;
        flex-direction: row-reverse;
        padding: 1.2rem 4.8rem 9.6rem;
    }
}

.nj .auth-page.login-page {
    background-image: url('/img/headers/nj.jpg');
}

.ca .auth-page.login-page {
    background-image: url('/img/headers/ca.jpg');
}

.il .auth-page.login-page {
    background-image: url('/img/headers/il.jpg');
}


.halloween1 .auth-page.login-page {
    background-image: url('/img/headers/halloween/halloween1.jpg');
}
.halloween2 .auth-page.login-page {
    background-image: url('/img/headers/halloween/halloween2.jpg');
}
.halloween3 .auth-page.login-page {
    background-image: url('/img/headers/halloween/halloween3.jpg');
}
.halloween4 .auth-page.login-page {
    background-image: url('/img/headers/halloween/halloween4.jpg');
}

.thanksgiving1 .auth-page.login-page {
    background-image: url('/img/headers/thanksgiving/thanksgiving1.jpg');
}
.thanksgiving2 .auth-page.login-page {
    background-image: url('/img/headers/thanksgiving/thanksgiving2.jpg');
}
.thanksgiving3 .auth-page.login-page {
    background-image: url('/img/headers/thanksgiving/thanksgiving3.jpg');
}
.thanksgiving4 .auth-page.login-page {
    background-image: url('/img/headers/thanksgiving/thanksgiving4.jpg');
}
.thanksgiving5 .auth-page.login-page {
    background-image: url('/img/headers/thanksgiving/thanksgiving5.jpg');
}
.thanksgiving6 .auth-page.login-page {
    background-image: url('/img/headers/thanksgiving/thanksgiving6.jpg');
}

.xmas1 .auth-page.login-page {
    background-image: url('/img/headers/xmas/xmas1.jpg');
}
.xmas2 .auth-page.login-page {
    background-image: url('/img/headers/xmas/xmas2.jpg');
}
.xmas3 .auth-page.login-page {
    background-image: url('/img/headers/xmas/xmas3.jpg');
}
.xmas4 .auth-page.login-page {
    background-image: url('/img/headers/xmas/xmas4.jpg');
}
.xmas5 .auth-page.login-page {
    background-image: url('/img/headers/xmas/xmas5.jpg');
}
.xmas6 .auth-page.login-page {
    background-image: url('/img/headers/xmas/xmas6.jpg');
}
