.list-filters {
    position: relative;
    .full-row {
        padding: 1.2rem 0;
        display: flex;
        align-items: center;
        width: 100%;
        > div {
            margin: 0 2.4rem;
            padding: 0 1.2rem;
            flex: 0 0 auto;
            max-width: 24rem;
            &:first-child {
                margin-left: 0;
                padding-left: 0;
            }
            &:last-child {
                margin-right: 0;
                padding-right: 0;
            }
            &.growing {
                flex: 1 1 auto;
                max-width: 100%;
            }
        }
        .text-filter {
            flex: 1 1 auto;
            width: 25%;
            max-width: 100%;
            margin-left: -2.4rem;
            input {
                width: 100%;
            }
        }
        .date-picker {
            max-width: 24rem;
        }
    }
    input {
        background-color: #fff;
    }
    .input-group.checkbox {
        background-color: #fff;
        span {
            display: inline-block;
        }
    }
    .ui-select-multiple {
        background-color: #fff;
    }
}

.list-page .list-filters {
    padding: 1.2rem 3.2rem;
    background-color: fadeout(@brand-primary, 25%);
    color: #fff;
    margin: 0 -3.2rem;
}

.slate-list-filters {
    input {
        background-color: #fff;
    }
    .input-group.checkbox {
        margin-top: 0;
        margin-bottom: 0;
        background-color: #fff;
        span {
            display: inline-block;
        }
    }
    .ui-select-multiple {
        background-color: #fff;
    }

    .q-row {
        display: flex;
        .q-field {
            flex: 1 1 auto;
            margin-right: 1.2rem;
        }
        .q-actions {
            flex: 0 0 auto;

            &> * {
                margin-left: 1.2rem;
            }
            
        }
    }
    .filters {
        display: none;

        &.toggled {
            display: block;
            label {
                margin-top: 1.2rem;
            }
        }
    }

}

.list-page .slate-list-filters {
    padding: 1.2rem 3.2rem;
    background-color: fadeout(@brand-primary, 25%);
    color: #fff;
    margin: 0 -3.2rem;
}

.list-contents {
    padding-top: 2.4rem;
    &.no-filters { padding-top: 0; }
    
    position: relative;
    .list-row {
        display: flex;
        align-items: center;
        margin: 0 -1.2rem;
        padding: 1.2rem;
        &:hover {
            background-color: darken(#fff, 10%);
        }
        .list-icon {
            font-size: 3.6rem;
            line-height: 1;
            flex: 0 0 auto;
            margin-right: 2.4rem;
            img {
                width: 3.2rem;
            }
        }
        .list-details {
            font-size: 1.6rem;
            line-height: 1.2;
            flex: 1 1 auto;
            .small {
                margin-top: 0.6rem;
                font-size: 1rem;
            }
        }
        .list-actions {
            flex: 0 0 auto;
            margin-left: 1.2rem;
            i {
                font-size: 1.8rem;
            }
        }
        &.selected {
            background-color: lighten(@brand-primary, 60%);
        }
    }
    .detail-row {
        display: flex;
        align-items: center;
        margin: 0 -1.2rem;
        padding: 0 1.2rem;
        &:hover {
            background-color: darken(#fff, 10%);
        }
        &.open {
            background-color: darken(#fff, 20%);
            border: 1px solid darken(#fff, 30%);
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25%);
        }
        > * {
            padding: 1.2rem;
            flex: 0 0 auto;
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
                text-align: right;
            }
        }
        .icon {
            max-width: 64px;
        }
        > .name {
            width: 30%;
            flex: 1 1 auto;
            border-left: 1px solid darken(#fff, 20%);
            max-width: 40%;
        }
        .name {
            font-size: 1.8rem;
            line-height: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        > .description {
            width: 30%;
            flex: 2 1 auto;
        }
        .description {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: @gray;
            a, a:visited {
                color: @gray;
            }
        }
        > .details {
            width: 30%;
            flex: 1 1 auto;
            border-left: 1px solid darken(#fff, 20%);
            max-width: 50%;
        }
        .actions {
            padding-left: 1.8rem;
        }
    }
    .list-footer {
        width: 100%;
        display: flex;
        align-items: center;
        > * {
            margin-right: 2.4rem;
            flex: 0 0 auto;
            &:last-child {
                margin-right: 0;
            }
            &.items-per-page-label {
                margin-right: 0;
            }
        }
        .pagination-sm > li > a, .pagination-sm > li > span {
            font-size: 1.8rem;
        }
        .spacer {
            flex: 1 1 auto;
        }
    }
    .table {
        > tbody {
            > tr.addon {
                > td {
                    padding: @table-cell-padding;
                    line-height: @line-height-base;
                    vertical-align: top;
                    border-top: 0;
                }
            }
        }
    }
}

.unscheduled-tasks {
    display: inline-block;
    vertical-align: top;
    width: 29%;
    .unsch {
        display: block;
        padding: 1.2rem;
        font-size: 1.8rem;
        line-height: 2.4rem;
        background-color: #99F;
    }
}

.schedule-grid {
    vertical-align: top;
    display: inline-block;
    overflow-x: scroll;
    position: relative;
    width: 70%;
    padding-top: 3rem;
    max-height: 48rem;
    white-space: nowrap;
    .time-row {
        background-color: #fff;
        z-index: 100;
        position: absolute;
        top: 0;
        left: 0;
        height: 3rem;
        padding: 0;
        padding-left: 36rem;
        .corner-controls {
            margin: 0;
            padding: 0.6rem;
            display: inline-block;
            width: 36rem;
            border-top: 1px solid transparent;
            border-left: 1px solid transparent;
            border-bottom: 1px solid #ccc;
            border-right: 1px solid #ccc;
            background-color: #fff;
            position: absolute;
            left: 0;
            top: 0;
        }
        .time-label {
            margin: 0;
            padding: 0.6rem;
            background-color: #fff;
            display: inline-block;
            width: 24rem;
            border: 1px solid #ccc;
            text-align: center;
        }
    }
    .block-row {
        position: relative;
        padding-left: 36rem;
        height: 7.4rem;
        .name {
            z-index: 600;
            background-color: #fff;
            position: absolute;
            left: 0;
            display: inline-block;
            width: 36rem;
            border: 1px solid #ccc;
            padding: 2.4rem;
            font-size: 2.4rem;
            line-height: 1;
        }
        .block {
            display: inline-block;
            width: 24rem;
            height: 7.4rem;
            border: 1px solid #ccc;
        }
    }
}

.table-header {
    background-color: @brand-primary;
    color: white;
}