.presphoto-upload-row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1em;
    .file-preview {
        width: 64px;
        height: 48px;
        overflow: hidden;
        background-size: cover;
        margin-right: 32px;
        img {
            width: 100%;
            -webkit-transform: translateZ(0);
            -moz-transform: translateZ(0);
            -ms-transform: translateZ(0);
            -o-transform: translateZ(0);
            transform: translateZ(0);
        }
    }
    .details {
        flex: 1 1 auto;
        .filename {
            font-size: 1.2em;
            line-height: 1.6;
            margin-bottom: 0.6rem;
        }
    }
    .actions {
        font-size: 24px;
        line-height: 1;
        padding: 0 0 0 1.2rem;
    }
}

.list-filters .full-row>div.pres-categories {
    display: flex;
    align-content: stretch;
    width: calc(100% - 42rem);
    max-width: none;
    margin-bottom: 1.2rem;
    .pres-category-group {
        width: 42rem;
        flex: 1 1 auto;
        max-width: 25%;
    }
}

div.pres-categories.edit-form {
    display: flex;
    align-content: stretch;
    width: 100%;
    margin-bottom: 1.2rem;
    .pres-category-group {
        width: 42rem;
        flex: 1 1 auto;
        max-width: 25%;
    }
}


.list-filters .pres-categories {
    .pres-category-group {
        margin-right: 3.2rem;
        .checkbox:last-child {
            margin-bottom: 0;
        }
    }
}

.modal-content .confidential-checkbox, .dialog-checkbox {
    font-size: 2.4rem;
    margin: 2.4rem 0;
    i {
        color: @brand-primary;
    }
}

.modal-content .pres-category-group .group-name {
    font-size: 1.4rem;
}

.modal-content .pres-category-group label {
    font-size: 1.2rem;
}

.pres-contents {
    display: flex;
}

.pres-alert {
    margin-right: 38rem;
    a { cursor: pointer; }
}

.presphotos-list {
    flex: 1 1 auto;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    .presphoto {
        flex: 0 0 auto;
        display: inline-block;
        box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.25);
        width: 200px;
        height: 150px;
        margin: 0 4.8rem 3.6rem 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        .actions {
            position: absolute;
            right: 10px;
            bottom: 10px;
            background-color: rgba(0, 0, 0, 0.4);
            color: rgb(255, 255, 255);
            border-radius: 50%;
            text-align: center;
            font-size: 15px;
            padding-top: 2px;
            width: 25px;
            height: 25px;
            display: none;
        }
        &.selected {
            box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.25), inset 0 0 10px fadeout(@brand-primary, 25%);
            border: 1px solid @brand-primary;
            border: 1px solid fadeout(@brand-primary, 25%);
        }
        &:hover { 
            .actions {
                display: block;
            }
        }
    }
}

.presentation-pane {
    background-color: @gray-lighter;
    flex: 0 0 auto;
    width: 35.1rem;
    min-height: 24rem;
    margin-top: -18rem;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.25);
    padding: 2.4rem;
    margin-bottom: 2.4rem;
    display: flex;
    flex-direction: column;
    &> * {
        flex: 0 0 auto;
    }
    .slides {
        flex: 1 1 auto;
        overflow: hidden;
        overflow-y: scroll;
        text-align: center;
        min-height: 15rem;
        .presphoto {
            display: inline-block;
            box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.25);
            width: 20rem;
            height: 15rem;
            margin: 1em;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
    .drop-message {
        text-align: center;
        padding: 4.8rem 2.4rem;
    }
    .slides-container {
        &.empty {
            border: 1px dashed rgba(0, 0, 0, 0.25);
        }
    }
}

.presPhotoUploadDropArea {
    border: 2px dashed lighten(@brand-primary, 20%);
    padding: 2.4rem 2.4rem 6.4rem;
    min-height: 34rem;
    position: relative;
    .noFiles {
        text-align: center;
        font-size: 3.2rem;
        color: @brand-primary;
        i {
            font-size: 12.8rem;
        }
    }
    .alert {
        margin-bottom: 0;
        position: absolute;
        bottom: 2.4rem;
        left: 2.4rem;
        right: 2.4rem;
    }
}