.datepicker.dropdown-menu {
  width: 250px;
  height: 250px;
  button {
    outline: none;
    border: 0px;
  }
  tbody {
    height: 180px;
  }
  tbody button {
    padding: 6px;
  }
  &.datepicker-mode-1, &.datepicker-mode-2 {
    tbody button {
      height: 65px;
    }
  }
  
}

.dropdown-menu {
  z-index: 1100;
}

.timepicker.dropdown-menu {
  padding: 0 4px;
  button {
    outline: none;
    border: 0px;
  }
  tbody button {
    padding: 6px;
  }
}

.glyphicon {
  display: inline-block;
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@fa-var-chevron-left: "\f053";
@fa-var-chevron-right: "\f054";

.glyphicon-chevron-left:before { content: @fa-var-chevron-left; }
.glyphicon-chevron-right:before { content: @fa-var-chevron-right; }
