.profileCropArea {
    border: 2px dashed rgba(0,0,0,0.5);
    overflow: hidden;
    width: 100%;
    height: 350px;
}
.photo-select-box {
    text-align: center;
}
slate-profile-photo-box {
    display: inline-block;
    position: relative;
    width: 100%;
    .upload-link {
        padding: 1em;
        background-color: rgba(0,0,0,0.4);
        color: #FFF;
        text-align: center;
        position: absolute;
        bottom: 0;
        width: 100%;
        display: none;
    }
    &:hover {
        .upload-link {
            display: block;
        }
    }
}

slate-profile-thumbnail {
    img { border-radius: 50%; }
}
