slate-request-row {
    min-width: 20rem;
    min-height: 3.6rem;
    max-width: 100%;
    display: block;
    &.ui-sortable-placeholder {
        height: 6rem;
        overflow: hidden;
    }
    &.ui-sortable-helper {
        background: rgba(255,255,255,0.5);
        max-width: 90%;
        width: 60vw;
        display: inline-block;
    }
    :focus {
        outline: none;
    }
    &.beingDragged {
        height: 3.6rem;
        margin-top: 1.2rem;
        margin-bottom: 1.2rem;
        border: 1px dashed @gray-light;
        
    }
}

.request-box {
    border-top: 1px solid @gray-lighter;
    &.toggled {
        margin: 0 0 2.4rem;
        box-shadow: 2px 2px 7px rgba(0,0,0,0.3);
        
    }
}
.request-row {
    display: flex;
    padding:1.2rem;
    margin: 0 -1.2rem;
    border-left: 6px solid @gray-lighter;
    align-items: center;
    &:hover {
        border-left: 6px solid @gray-light;

    }

    > * {
        margin: 0 1.2rem;
        flex: 0 0 auto;
    }
    .handle {
        font-size: 2.4rem;
        line-height: 1;
        padding: 0;
        margin: 0 0.5rem 0 -0.5rem;
        color: @gray-light;

    }
    .completion {
        margin-left: 0rem;
        font-size: 3.2rem;
        line-height: 1;
    }
    .details {
        margin-left: 0;
        flex: 2 1 auto;
        text-overflow: ellipsis;
        &.low-priority {
            color: @gray-light;
        }
        .name {
            font-size: 1.6rem;
            line-height: 1;
            margin-bottom: 0.6rem;
        }
    }
    .hours {
        width: 18rem;
    }
    .actions {
        font-size: 3.2rem;
        line-height: 1;
    }
    .assignments {
        slate-profile-thumbnail {
            display: inline-block;
            width: 4.8rem;
            height: 4.8rem;
        }
    }

    &.Design {
        border-left-color: lighten(#f38b3c, 40%);
        &:hover { border-left-color: #f38b3c; }
    }
    &.IT_Staff {
        border-left-color: lighten(#4492c6, 40%);
        &:hover { border-left-color: #4492c6; }
    }
    &.Finance {
        border-left-color: lighten(#28b29a, 40%);
        &:hover { border-left-color: #28b29a; }
    }

    &.Enquiry { border-left-color: #4492c6; }
    &.Development { border-left-color: #f38b3c; }
    &.Production { border-left-color: #e32020; }
    &.Delivery { border-left-color: #9361b0; }
    &.Invoicing { border-left-color: #28b29a; }
}


.request-data {
    display: flex;

    .empty {
        width: 94%;
        margin: 0 auto;
        border: 1px dashed @gray-light;
        text-align: center;
        padding: 2.4rem;
    }
    
    .notes { 
        max-width: 70rem;
        width: 100%;
        overflow-wrap: break-word;
    }

    .left {
        flex: 2 1 auto;
        display: inline-block;
        padding: 1.2rem;
        font-size: 1.4rem;

    }

    .right {
        flex: 1 0 auto;
        min-width: 38rem;
        max-width: 40%;
        padding: 1.2rem;
    }
}
