slate-approval-status {
    background-color: fadeout(@brand-primary, 25%);
    display: block;

    margin: 0 0 2.4rem;
    padding: 1.2rem 2.4rem;
    color: #FFF;
    a {
        color: #fff;
    }
    .mainbox {
        display: flex;
        align-items: center;
        .details {
            flex: 1 1 auto;
        }
        .actions {
            flex: 0 0 auto;
        }
    }
    .notes {
        display: none;
        position: relative;
        margin-top: 2.4rem;
        textarea {
            background-color: #FFF;
        }
        &.show {
            display: block;
        }
    }
}
.approval-steps {
    width: 100%;
    display: block;
    padding: 0;
    .step {
        display: flex;
        width: 100%;
        align-items: center;
        padding: 1.2rem 0;
        .tag {
            margin: 0 0 0 1.2rem;
        }
        .approvers {
            margin: 0 2.4rem;
            flex: 1 1 auto;
        }
    }
    .toDelete {
        background-color: #ff0000;
    }
}
