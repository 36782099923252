slate-control-panels-menu {
    padding: 2.4rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2.4rem;
    .control-pane-option {
        padding: 2.4rem;
        width: 12.8rem;
        box-shadow: 2px 2px 5px rgba(0,0,0,0.25);
        flex: 0 0 auto;
        cursor: pointer;

        text-align: center;
        font-size: 1.4rem;
        display: inline-block;
        background-color: @gray-lighter;
        border: 1px solid @gray-light;

        .far {
            font-size: 4.8rem;
        }
    }
}


.material-editor {

    .material-row {
        display: flex;
        align-items: center;
        margin-bottom: 1.2rem;
        
    }

    .handle {
        margin-right: 0.6rem;
        font-size: 2.0rem;
        flex: 0 0 auto;
    }
    
    .name {
        margin-left: 1.2rem;
        flex: 1 1 auto;
    }
    .actions {
        margin-left: 1.2rem;
        text-align: right;
        flex: 0 0 auto;
    }
    
}