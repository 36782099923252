
slate-navbar {
    font-family: @headings-font-family;
    font-weight: normal;
    font-size: 1.6rem;
    letter-spacing: 1px;
    background-color: #fff;
    box-shadow: 0 0.6rem 0.6rem rgba(0, 0, 0, 0.15);
    color: @brand-primary;
    display: flex;
    align-items: center;
    height: 4.8rem;
    z-index: 8888;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 inherit;
    // Prevent floats from breaking the navbar
    &:extend(.clearfix all);
    &.betasite {
        background: url(/img/beta.png), #fff;
        background-size: 100px 100px;
    }
    .brand {
        flex: 0 0 auto;
        margin-right: 1.2rem;
        height: 2.4rem;
        display: flex;
        align-items: center;
        .far {
            font-size: 2.8rem;
            line-height: 3.6rem;
            margin-right: 0.44rem;    
        }
        img {
            max-width: 100%;
            height: 2.4rem;
        }
    }
    .items {
        flex: 1 1 auto;
        height: 100%;
        text-align: right;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        .notifications {
            text-align: right;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            >* {
                flex: 0 0 auto;
            }
            order:2;
        }
        .nav {
            text-align: center;
            flex: 1 1 auto;
            order: 1;
            transition: opacity 0.25s;
            &.searching {
                opacity: 0;
            }
        }
        &.visible-print-block {
            @media print {
                display: flex!important;
            }
        }
    }
    ul.menu {
        color: @brand-primary; // rgb(45, 133, 191);
        flex: 1 1 auto;
        text-align: center;
        list-style: none;
        display: inline-block;
        padding: 0;
        margin: 0;
        li {
            display: inline-block;
            padding-right: 0.75em;
            a {
                color: @brand-primary;
                text-transform: uppercase;
                &:hover, &:focus {
                    color: darken(@brand-primary, 10%);
                    text-decoration: none;
                }
                &.active {
                    color: darken(@brand-primary, 10%);
                    border-bottom: 1px solid darken(@brand-primary, 10%);
                    &.home {
                        border-bottom: 0px;
                    }
                }
            }
        }
        .dropdown {
            a.icon .label {
                top: 1em;
            }
            li {
                display: block;
            }
        }
    }
    .icon {
        font-size: 2.4rem;
        line-height: 1;
        position: relative;
        display: inline-block;
        margin-left: 1.2rem;
        .label {
            font-size: 0.8rem;
            padding: 0.24rem 0.48rem;
            border-radius: 0.24rem;
            position: absolute;
            right: -0.6em;
            top: 0;
        }
    }
    .search {
        flex: 1 1 auto;
        input[type=text] {
            opacity: 0;
            font-size: 2.0rem;
            padding: 0.4rem 0.8rem;
            border-color: lighten(@brand-primary, 20%);
            border-style: solid;
            transition: width 0.35s, opacity 0.25s;
            width: 0;
        }
        &.searching {
            input[type=text] {
                opacity: 1;
                width: 24rem;
            }
        }
    }
    a {
        &:hover, &:focus {
            color: darken(@brand-primary, 10%);
            text-decoration: none;
        }
        &.active {
            color: darken(@brand-primary, 10%);
            border-bottom: 1px solid darken(@brand-primary, 10%);
        }
    }
}