.contact-edit-row {
    //width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    box-sizing: border-box;
    margin: 0 -12px;

    .form-group { margin-bottom: 0px; }

    .contact-primary {
        flex: 0 0 auto;
        padding-right: 0.6rem;
    }

    .contact-method {
        flex: 1 35%;
    }

    .contact-value {
        flex: 1 65%;
        padding: 0 12px;
    }

    .contact-options {
        flex: 0 32px;
        font-size: 24px;
        text-align: right;
    }

}
