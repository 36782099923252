.wizard {
    position: relative;
    border-bottom: 3px solid @brand-primary;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.6rem;
    .step {
        font-size: 1.6rem;
        line-height: 1.8rem;
        display: inline-block;
        flex: 0 0 auto;
        background-color: #fff;
        border: 3px solid @brand-primary;
        border-radius: 0.6rem;
        padding: 0.6rem;
        margin-bottom: -1.6rem;

    }

}