#popout-buttons {
    position: fixed;
    top: 12.8rem;
    right: 0;
    min-height: 6.4rem;
    min-width: 3.2rem;
    z-index: 9999;

    .popout-button {
        background-color: @brand-primary;
        width: 4.8rem;
        border-top-left-radius: 0.6rem;
        border-bottom-left-radius: 0.6rem;
        box-shadow: 0px 0px 16px rgba(0,0,0,0.35);
        color: rgb(255,255,255);
        font-size: 2.4rem;
        padding-top:1.2rem;
        padding-bottom:1.2rem;
        text-align: center;

        a { color: rgb(255,255,255); }
    }
}