.file-browser {
    margin-bottom: 4.8rem;
    .breadcrumbs {
        margin-bottom: 1.2rem;
        display: flex;
        .crumbs {
            flex: 1 1 auto;
            font-size: 2.4rem;
        }
        .actions {
            display: inline-block;
            flex: 0 0 auto;
        }
    }
    .directories {
        &.dragover {
            .uploadDropArea {
                display: block;
            }
        }
        .uploadDropArea {
            display: none;
            margin-top: 2.4rem;
            border: 2px dashed lighten(@brand-primary, 20%);
            padding: 2.4rem 2.4rem 6.4rem;
            min-height: 34rem;
            position: relative;
            &.hasFiles {
                display: block;
            }
            .noFiles {
                text-align: center;
                font-size: 3.2rem;
                color: @brand-primary;
                i {
                    font-size: 12.8rem;
                }
            }
            .alert {
                margin-bottom: 0;
                position: absolute;
                bottom: 2.4rem;
                left: 2.4rem;
                right: 2.4rem;
            }
        }
    }
    .file-details {
        display: flex;
        flex-wrap: wrap;
        .filename {
            width: 100%;
            padding: 2.4rem 0;
            font-size: 3.6rem;
        }
        .details {
            margin-right: 2.4rem;
        }
        .versions {
            flex: 1 1 auto;
        }
    }
    table {
        .versions {
            .actions {
                i {
                    font-size: 1.6rem;
                }
            }
        }
    }
}


.attachmentUploadDropArea {

    .upload-select {
        display: flex;
        padding: 1.2rem;
        margin-left: -1.2rem;
        margin-right: -1.2rem;
        > * {
            display: inline-block;
            flex: 1 1 auto;
            width: 50%;
            &.actions { text-align: right; }
        }

    }
    &.dragover {
        .upload-select { border: 1px dashed @gray-light; }
    }

    .attachment-upload-row {
        display: flex;
        align-items: center;
        margin: 0.6rem 0;

        .description {
            width: 30%;
            flex: 1 1 auto;
        }
        .filename {
            width: 60%;
            flex: 1 1 auto;
            margin-left: 1.2rem;
        }
        .actions {
            flex: 0 0 auto;
            margin-left: 0.6rem;
        }
    }


}

slate-file-browser-attachments {

    .attachments-box {
        border-top: 1px solid @gray-lighter;
        padding-top: 0.6rem;
    }

    .filebox {
        display: flex;
        padding: 0.6rem;
        margin: 0 -0.6rem;
        &:hover {
            background-color: @gray-lighter;
        }
        > * { flex: 0 0 auto; }
        .filename {
            flex: 1 1 auto;
            margin: 0 1.2rem;
        }

    }
    .images {
        margin-top: 1.2rem;
    .imagebox {
        display: inline-block;
        margin: 0 1.2rem 1.2rem 0;
        width: 7rem;
        height: 7rem;
        padding: 0.6rem;
        box-shadow: 2px 2px 5px rgba(0,0,0,0.25);

        img { max-width: 100%; max-height: 100%; vertical-align: top; }
    }
}
}
