.roles-list-cp {
    display: flex;
    table {
        flex: 1 0 auto;
        max-width: 48rem;
    }
    .instructions {
        flex: 1 1 auto;
        padding: 0 4.8rem;
        font-size: 1.6rem;
        line-height: 1.6;
        h3 {
            line-height: 1;
            margin-top: 0;
            margin-bottom: 2.4rem;
        }
        p {
            margin-bottom: 2.4rem;
        }
    }
}
.roles-permissions-list {
    .heading {
        width: 100%;
        margin-bottom: 4.8rem;
        .big-checkbox {
            font-size: 1.8rem;
            margin-top: 2.4rem;
            i {
                color: @brand-primary;
            }
        }
    }
    .role-cp-body {
        display: flex;
        .role-settings {
            flex: 0 0 auto;
            width: 72rem;
            .role-group {
                h4 {
                    margin-top: 0;
                }
                margin-top: 4.8rem;
                &:first-child {
                    margin-top: 0;
                }
                border-bottom: 1px solid rgba(0,0,0,0.1);
            }
            .role-line {
                padding: 0.6rem;
                margin: 0 -0.6rem;
                font-size: 1.6rem;
                display: flex;
                align-items: center;
                &:hover {
                    background-color: fadeout(@brand-primary, 80%);
                }
                .desc {
                    flex: 1 1 auto;
                }
                .btn-group {
                    flex: 0 0 auto;
                }
            }
        }
        .instructions {
            flex: 1 1 auto;
            padding: 0 4.8rem;
            font-size: 1.6rem;
            line-height: 1.6;
            h3 {
                line-height: 1;
                margin-top: 0;
                margin-bottom: 2.4rem;
            }
            p {
                margin-bottom: 2.4rem;
            }
        }
    }
}
