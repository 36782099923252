.skuline {
    display: flex;
    margin: 0 -1.2rem;
    padding: 0.24rem 1.2rem;
    .sku-name {
        flex: 1 1 auto;
        margin-left: 2.4rem;
        margin-right: 2.4rem;
        
    }
    .sku-component-select {
        max-width: 25em;
        flex: 1 1 auto;
    }
    .sku-qty {
        width: 15em;
        flex: 0 0 auto;
    }
    .sku-actions {
        flex: 0 0 auto;
        margin-left: 1.2rem;
        font-size: 2.4rem;
    }

    &.to-delete { 
        background-color: rgba(255,0,0,0.25);
        input {
            background-color: rgb(250,250,250);
        }
    }
}

.qcrequest-alert {
    a {
        color: #000;
    }
}

.qcrequests-widget-line {
    &:hover { background-color: rbga(0,0,0,0.25); }
    border-bottom: 1px solid rgba(0,0,0,0.25);
    padding: 0.6rem 0;
    display: flex;
    margin-bottom: 0.6rem;
    align-items: center;
    .status {
        font-size: 2.4rem;
        flex:0 0 auto;
        margin-right:0.6rem;
    }
    .details {
        flex:1 1 auto;
        .name { 
            font-size: 1.6rem;
            font-weight: bold;
            
        }
        .subdetails {
            display: flex;
        }
    }
    .assignment {
        max-width: 40%;
    }
}