.sort-fields {
    background: #ddd;

    >th.sort-field {
        cursor: pointer;

        &.active,
        &:hover {
            background-color: #d3d3d3;
        }
    }
}

.time-entry {
    height: 100px;
    padding: 8px;
    border: 2px solid @brand-primary;
    margin-bottom: 8px;
}

.task-title {
    font-size: small;
    margin-bottom: 8px;
}

.clip-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

div.container-fluid.reports-page {
    width: 100%;
    
}