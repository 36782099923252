.content-pane.project-status {
    display: flex;
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;

    > div {
        margin-left: 2.4rem;
        flex: 1 1 auto;
        text-align: center;
        font-size: 1.6rem;
        padding: 1.2rem;
        &:first-child { margin-left: 0; }


        &.hours-spent { background-color: #4492c6; }
        &.expenses { background-color: #f38b3c; }
        &.hours-cost { background-color: #28b29a; }
        &.project-cost { background-color: #9361b0; }

    }

}

.brief {
    display:flex;
    > div {
        margin-left: 2.4rem;
        flex: 1 1 auto;
        &:first-child { margin-left: 0; }
        max-width: 50%;
    }
}
