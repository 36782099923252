.schedule-basic-day {
    margin: 1.2rem 0;
    .schedule-basic-header {
        display: flex;
        .schedule-basic-back {
            flex: 0 0 auto;
            width: 2em;
        }
        .schedule-basic-display {
            flex: 1 0 auto;
            text-align: center;
        }
        .schedule-basic-forward {
            flex: 0 0 auto;
            width: 2em;
            text-align: right;
        }
    }

    
    .schedule-basic-timeblock {
        
    }
}