table.table-responsive>tbody>tr>td.responsive-toggle { 
    display: none;
}

@media only screen and (min-width: 769px) {

.table-responsive-wrapper {
	overflow-x: scroll;
	max-width: 100%;
	position: relative;

	.sticky {
		position: sticky;
		left: 0;
		z-index: 501;
	}
	tr td.sticky {
		background: white;
	}
	tr.odd .sticky {
		background-color: @table-bg-accent;
	}
	

	.selector {
		width: 2.8rem;
	}

	thead {
		position: sticky;
		top: 0;
		z-index: 200;
		th {
			white-space: nowrap;
		}
	}
	tbody {
		position: relative;
		tr {
			position: relative;
			td {
				white-space: nowrap;
				
			}
		}
	}
}
}

@media only screen and (max-width: @screen-sm) {
	
	

	/* Force table to not be like tables anymore */
	table.table-responsive, 
	table.table-responsive thead, 
	table.table-responsive tbody, 
	table.table-responsive th, 
	table.table-responsive td, 
	table.table-responsive tr { 
		display: block; 
	}

	/* Hide table headers (but not display: none;, for accessibility) */
	table.table-responsive>thead>tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
 
	table.table-responsive tr { border: 1px solid #ccc; }

    table.table-responsive>tbody>tr>td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 30%;
		white-space: normal;
        text-align:left;
        display: none;
		min-height: 1.8rem;
		> span:empty {
			height: 1.6rem;
			display: block;
			
		}
    }

    table.table-responsive>tbody>tr.responsive-show>td,
    table.table-responsive>tbody>tr>td.responsive-toggle { 
        display: block;
    }
    table.table-responsive>tbody>tr>td.responsive-toggle { 
        padding-left: 1.2rem;
        padding-right: 6.4rem;
        font-size: 1.8rem;
        .actions {
            position: absolute;
            right: 0.8rem;
            top: 0.8rem;
        }
    }
 
	table.table-responsive td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 25%; 
		padding-right: 10px; 
		white-space: nowrap;
		text-align:left;
        font-weight: bold;
        overflow: hidden;
	}
 
	/*
	Label the data
	*/
	table.table-responsive td:before { content: attr(data-title); }
}