slate-workflow-row {
    min-width: 20rem;
    min-height: 3.6rem;
    display: block;
    .ui-sortable-placeholder {
        height: 6rem;
    }
    :focus {
        outline: none;
    }
}

.workflows .beingDragged {
    height: 3.6rem;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    border: 1px dashed @gray-light;
}


.workflow-row {
    display: flex;
    padding:1.2rem;
    margin: 0 -1.2rem;
    align-items: center;
    &:hover {
        background-color: @gray-lighter;
    }

    > * {
        margin: 0 1.2rem;
        flex: 0 0 auto;
    }
    .handle {
        font-size: 1.8rem;
        line-height: 1;
        padding: 0;
        margin: 0 0 0 0;
    }
    .completion {
        //margin-left: -0.6rem;
        font-size: 2.4rem;
        line-height: 1;
    }
    .details {
        margin-left: 0;
        flex: 2 1 auto;
        text-overflow: ellipsis;
        &.low-priority {
            color: @gray-light;
        }
        .name {
            font-size: 1.4rem;
            line-height: 1;
            margin-bottom: 0.6rem;
        }
    }
    .hours {
        width: 16rem;
    }
    .actions {
        font-size: 2.4rem;
        line-height: 1;
    }
    .assignments {
        slate-profile-thumbnail {
            display: inline-block;
            width: 3.6rem;
            height: 3.6rem;
        }
    }

    &.Enquiry { border-left-color: #4492c6; }
    &.Development { border-left-color: #f38b3c; }
    &.Production { border-left-color: #e32020; }
    &.Delivery { border-left-color: #9361b0; }
    &.Invoicing { border-left-color: #28b29a; }
}


.workflow-data {
    display: flex;

    .empty {
        width: 94%;
        margin: 0 auto;
        border: 1px dashed @gray-light;
        text-align: center;
        padding: 2.4rem;
    }

    .data {
        flex: 1 1 auto;
        display: inline-block;
        padding: 1.2rem;
        font-size: 1.4rem;

    }

    .timetracking {
        flex: 0 0 auto;
        min-width: 38rem;
        padding: 1.2rem;
    }
}
.timebox {
    margin-bottom: 0.6rem;
    border: 1px solid rgba(0,0,0,0.25);
    padding: 0.6rem;
    display: flex;
    align-items: center;
    > div {
        margin: 0 1.2rem;
        flex: 0 0 auto;
        &.expand { flex: 1 1 auto; }
        &:first-child { margin-left: 0; }
        &:last-child { margin-right: 0; }
        &.avatar { width: 3.6rem; }
    }
}

.workflowedit-checklist-row {
    display: flex;
    align-items: center;
    margin-bottom: 2.4rem;
    .handle { flex: 0 0 auto; font-size: 2.4rem;}
    .text { flex: 1 1 auto; }
}
