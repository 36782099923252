.modal.modal-sidebar {
    position: fixed;
    top: 0;
    height: 100%;
    height: 100vh;
    max-width: 90%;
    overflow-y: scroll;
    display: block;
    transition: all 300ms;
    background: #FFFFFF;
    box-shadow: 0px 0px 1rem rgba(0, 0, 0, 0.35);
    z-index: @zindex-modal;
    margin: 0;

    &.left {
        left: 0;
        display: block;
    }

    &.right {
        left: auto;
        right: 0;
        display: block;
    }

    .modal-dialog {
        width: 36rem;
        margin: 0;

        &.modal-md {
            width: 48rem;
        }
        &.modal-lg {
            width: 64rem;
        }
        &.modal-xl {
            width: 50%;
            width: 50vw;
        }

    }

    .modal-content {
        box-shadow: none;
        border: none;

    }

}

.sidebar {
    color: @brand-primary;

    .sidebarclose {
        font-size: 2.8rem;
        padding-left: 3.2rem;
        margin-bottom: 2.4rem;
        height: 7.2rem;
        display: flex;
        align-items: center;
        .brand {
            flex: 0 0 auto;
            margin-right: 1.2rem;
            height: 3.6rem;
            display: flex;
            align-items: center;
            .far {
                font-size: 2.8rem;
                line-height: 3.6rem;
                margin-right: 0.44rem;
            }
            img {
                max-width: 100%;
                height: 3.6rem;
            }
        }
        border-bottom: 1px solid rgb(221,221,221);
    }
    position: fixed;
    top: 0;
    height: 100%;
    height: 100vh;
    width: 36rem;
    max-width: 90%;
    overflow-y: scroll;
    z-index: 9999;
    display: block;
    transition: all 300ms;
    background: #FFFFFF;
    box-shadow: 0px 0px 1rem rgba(0, 0, 0, 0.35);

    &.left {
        margin-left: -37rem;
        &.active {
            margin-left: 0;
        }
    }

    ul {

        list-style: none;
        font-size: 1.8rem;
        li {
            margin-bottom: 1.2rem;
        }
    }

}

.sidebar-shadow {
    background-color: rgba(0,0,0,0.0);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9998;
    display: none;
    transform: all 300ms;

    &.active {
        background-color: rgba(0,0,0,0.5);
        display: block;

    }
}