.badge {
    &-success {
        background-color: @brand-success;
    }

    &-danger {
        background-color: @brand-danger;
    }

    &-warning {
        background-color: @brand-warning;
    }

    &-default {
        background-color: @gray;
    }
}

.status-indicator {
    display: flex;
    align-items: center;
}

.status {
    border: 1px solid;
    // border-radius: 50px;
    padding: 8px 16px;
    font-size: 14px;
    text-align: center;

    &&-complete {
        background: @brand-primary;
        border-color: @brand-primary;
        color: white;
    }

    &&-incomplete {
        border-color: @brand-primary;
        color: @brand-primary;
    }

    &&-success {
        background: @brand-success;
        border-color: @brand-success;
        color: white;
    }

    &&-danger {
        background: @brand-danger;
        border-color: @brand-danger;
        color: white;
    }

}

.status-connector {
    border: 1px solid;
    width: 50px;
    height: 0;

    &.complete {
        border-color: @brand-primary;
        background: @brand-primary;
    }

    &.incomplete {
        border-color: @gray;
        background: @gray;
    }
}

.icon-dropzone {
    display: flex;
    
    &&-lg {
        align-items: center;
        justify-content: center;
    }

    &&-right {
        justify-content: flex-end;
    }
}

.shipping-mark {
    width: 32px;

    &-lg {
        width: 64px;
    }

    &-xl {
        width: 128px;
    }
}


.highlight {
    border: 1px solid @brand-primary;
}
