.settings-group {
    h4 {
        margin-top: 0;
    }
    margin-top: 4.8rem;
    &:first-child {
        margin-top: 0;
    }
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
.settings-line {
    padding: 0.6rem;
    margin: 0 -0.6rem;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    &:hover {
        background-color: fadeout(@brand-primary, 80%);
    }
    .desc {
        flex: 1 1 auto;
    }
    .actions {
        flex: 0 0 auto;
        &.wide {
            width: 25%;
        }
    }
}
