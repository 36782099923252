.slate-footer {
    //height: 28rem;
    //position: absolute;
    //bottom: 0;
    //left: 0;
    //right: 0;
    //height: 26rem;
    flex: 0 0 auto;
    background-image: url(/img/footer-bg.jpg);
    background-position: top center;
    background-size: cover;
    text-align: center;
    padding: 3.6rem 4.8rem 2.4rem;
    color: #fff;

    a,
    a:visited,
    a:hover {
        color: #fff;
    }

    .logos {
        display: flex;
        justify-content: center;
    }

    .logo {
        max-width: 50%;

        margin: 0 12px;
        text-align: center;

        a {
            line-height: 1.6;
            letter-spacing: 0.25rem;

        }

        img {
            width: 60%;
            max-width: 20rem;
        }
    }

    .locations {
        margin-bottom: 1.8rem;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 1.2rem;

        .location {
            text-align: center;
            font-size: 1rem;

            .name {
                position: relative;
                font-size: 1.6rem;
                text-transform: uppercase;
                // &::before {
                //     font-family: "Material-Design-Iconic-Font";
                //     content: '\f1ab';
                //     position: absolute;
                //     left: -1.8rem;
                // }
            }

        }
    }

    .legal {
        font-size: 0.8rem;
    }

}