/*!
 * Bootstrap v3.3.5 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
/*
@import "fontawesome/fontawesome.less";
@import "fontawesome/brands.less";
@import "fontawesome/regular.less";
@import 'fontawesome/_variables.less';
@import 'fontawesome/_shims.less';
 */

// Core variables and mixins
@import "../../node_modules/bootstrap/less/variables.less";
@import "../../node_modules/bootstrap/less/mixins.less";
@import "bootswatch-variables.less";
@import (optional) "bootswatch-variables-local.less";
@import "variables.less"; // Reset and dependencies
@import (optional) "variables-local.less";
@import "../../node_modules/bootstrap/less/normalize.less"; //@import "../../vendor/bootstrap/less/print.less";
@import "bootswatch.less";


@import "leaflet.less";
@import "navbar.less";
@import "footer.less";
@import "details.less";
@import "list.less";
@import "settings.less";
@import "auth.less";
@import "roles.less";
@import "projects.less";
@import "profilephotos.less";
@import "presentations.less";
@import "contact-edit-row.less";
@import "filebrowser.less";
@import "approvalcycle.less";
@import "workflows.less";
@import "requests.less";
@import "datepicker.less"; //@import "ui-sortable.less";
@import "file-type-font.less";
@import "dashboard.less";
@import "notifications.less";
@import "controlpanels.less";
@import "reports.less";
@import "schedule.less";
@import "qc.less";
@import "inspections.less";
@import "logistics.less";
@import "sidebar.less";
@import "tables-responsive.less";
@import "wizard.less";
@import "orderplan.less";
@import "popout.less";


html {
    overflow-y: scroll;
    height: 100%;

    @media print {
        font-size: 8pt;
        width: 1440px !important;

        table {
            font-size: 1rem;
        }

    }
}

div[uib-modal-window] {
    display: none;
}

[draggable] {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    -khtml-user-drag: element;
    -webkit-user-drag: element;
}

body {
    background-color: #fff;
    min-height: 100%;
}

a:focus {
    outline: none;
}

.site_wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    //padding-top: 4.8rem;
}

#nojs {
    display: none;

    .alert {
        margin-bottom: 0;
    }
}

.no-js {
    #nojs {
        display: block;

    }
}

#content {
    flex: 1 1 auto;
    font-size: 1.2rem;
    background-color: #FFF;
    margin: auto 0 0;
    min-height: 480px;
    min-height: calc(100vh - 26rem);
    width: 100%;
    padding-top: 4.8rem;

    display: flex;
    flex-direction: column;
}

.content-pane {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
}

.panel-title {
    .edit-title {
        display: flex;
        align-items: center;

        .header {
            flex: 1 1 auto;

            .form-group {
                margin-bottom: 0;
            }

            input {
                padding-top: 0.24em;
                padding-bottom: 0.24em;
                height: auto;
            }
        }

        .actions {
            padding: 0.48rem;
            text-align: right;
            font-size: 1.8rem;
        }
    }
}

uib-accordion .panel-heading * {
    outline: 0 !important;
}

accordion .panel-heading * {
    outline: 0 !important;
}

.accordion-toggle {
    cursor: pointer;
}

.table {
    tr.odd {
        background-color: @table-bg-accent;
    }

    tr.bg-warning {
        .bg-variant(@state-warning-bg);
    }
}

.alert.alert-primary a {
    color: #bbb;

    &:visited {
        color: #bbb;
    }

    &:hover {
        color: #fff;
    }
}

#alerts-box {
    position: absolute;
    left: 32px;
    bottom: 16px;
    width: 33%;
    max-width: 400px;
}

.details_left_col {
    overflow: hidden;
    text-overflow: ellipsis;
}

slate-task .finished div {
    text-decoration: line-through;

    .nostrike {
        text-decoration: none;
    }
}

.milestone .done-bar {
    width: 200px;
}

.wizard-progress {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;

    span.step {
        font-size: @font-size-h4;
        text-align: center;
        flex: 1 1 auto;

        &.active {
            border-bottom: 3px solid @brand-primary;
        }
    }
}

.filearea .node {
    margin-bottom: 0.5em;

    .selected {
        font-weight: bold;
    }
}

.upload-drop-box {
    background: #F8F8F8;
    border: 5px dashed #DDD;
    text-align: center;
    padding: 15px 0 25px;
}

// warning form boxes
.has-warning input.form-control {
    outline: 0;
    @shadow: inset 0 1px 1px rgba(0, 0, 0, .075),
    0 0 6px lighten(@brand-warning, 20%);
    .box-shadow(@shadow);
}

.has-error input.form-control {
    outline: 0;
    @shadow: inset 0 1px 1px rgba(0, 0, 0, .075),
    0 0 6px lighten(@brand-danger, 20%);
    .box-shadow(@shadow);
}

.panel-title .indicator:before {
    content: "\f078";
}

.panel-title.collapsed .indicator:before {
    content: "\f054";
}

.force-wrap {
    word-wrap: break-word;
}

.loading-box {
    font-size: 3.6rem;
    text-align: center;
    padding: 4.8rem;

    .fa-spin {
        -webkit-animation: spin 2s infinite linear;
        animation: spin 2s infinite linear;

        @-webkit-keyframes spin {
            0% {
                -webkit-transform: rotate(0deg);
            }

            100% {
                -webkit-transform: rotate(360deg);
            }
        }

        @keyframes spin {
            0% {
                -webkit-transform: rotate(0deg);
            }

            100% {
                -webkit-transform: rotate(360deg);
            }
        }
    }
}

.profile-header {
    h1 {
        margin-top: 0;
    }
}

.profile-address {
    i {
        float: left;
    }

    address {
        display: inline-block;
    }
}

.ui-select-multiple.ui-select-bootstrap .ui-select-match .close {
    color: @brand-primary;
}

@zmdi-li-width: (30em / 14);

.mdi-hc-ul {
    padding-left: 0;
    margin-left: @zmdi-li-width;
    list-style-type: none;

    >li {
        position: relative;
    }
}

.mdi-hc-li {
    position: absolute;
    left: -@zmdi-li-width;
    width: @zmdi-li-width;
    top: (2em / 14);
    text-align: center;

    &.mdi-hc-lg {
        left: (-@zmdi-li-width + (4em / 14));
    }
}

.form-row {
    display: flex;
    align-items: center;

    >div {
        margin: 0 1.2rem;
        flex: 0 0 auto;

        &.expand {
            flex: 1 1 auto;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.empty-list {
    width: 94%;
    margin: 0 auto;
    border: 1px dashed @gray-light;
    text-align: center;
    padding: 2.4rem;
}

@media (min-width: @screen-md-min) {
    .modal-xl {
        max-width: 1440px;
        width: 90%;
    }
}

.ui-select-container {
    input {
        width: 100%;
        box-sizing: border-box;
    }
}

.btn-group>.dropdown-menu>li>a {
    padding: 6px 15px;
}

.fr-popup .fr-command.fr-btn span,
.fr-toolbar .fr-command.fr-btn span.fr-sr-only {
    display: none;
}

.force-scrollbar {
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 11px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 11px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white;
        /* should match background, can't be transparent */
        background-color: rgba(0, 0, 0, .5);
    }

    &::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: 8px;
    }
}

.fr-wrapper .fr-view td,
.fr-wrapper .fr-view th {
    border: 1px solid rgba(0, 0, 0, .75);
}

.form-group .dropdown-menu {
    max-height: 25em;
    max-width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

//get rid of reidiculous margins on pagers
.pagination {
    margin: 0 auto;
}

.pager {
    margin: 1.2rem auto;
}

.deleted-item {
    text-decoration: line-through;
}

.rfq_response_paginator {
    display: flex;
    align-items: center;

    .name {
        flex: 1 1 auto;
        margin: auto 1.2rem;
    }
}

.ui-select-toggle.btn-default {
    background-color: transparent;
}

@media (min-width: @screen-sm-min) {

    // Automatically set modal's width for larger viewports
    .modal-dialog {
        margin: 5rem auto;
    }
}

@media (min-width: @screen-md-min) {
    .modal-dialog {
        margin: 6.4rem auto;
    }
}

.discussion-msg-urgent {
    padding: 0.6rem 0;
    box-shadow: inset 0 0 5px #f0ad4e, inset 0 0 25px yellow, ;
}

.discussion-box {}

.nav.nav-tabs>li>* {
    display: inline-block;
    width: 100%;
}